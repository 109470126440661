<template>
    <layout-full-width :title="$tc('organization')">
        <div class="row">

            <!-- BILLING ADDRESS -->
            <div class="col-6">
                <div class="card">
                    <div class="card-header">
                        <strong>{{ $t('billing-address') }}</strong>
                    </div>
                    <template v-if="!loading.billing && !edit.billing">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('salutation', 1) }}
                                </strong>
                                {{ $t('salutations.' + profile.billingAddress.salutation) }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('first-name', 1) }}
                                </strong>
                                {{ profile.billingAddress.firstName }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('last-name', 1) }}
                                </strong>
                                {{ profile.billingAddress.lastName }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('company', 1) }}
                                </strong>
                                {{ profile.billingAddress.company }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('street', 1) }}
                                </strong>
                                {{ profile.billingAddress.street }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('postcode', 1) }}
                                </strong>
                                {{ profile.billingAddress.postcode }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('city', 1) }}
                                </strong>
                                {{ profile.billingAddress.city }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('state', 1) }}
                                </strong>
                                {{ profile.billingAddress.state }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">{{ $tc('country', 1) }}</strong>
                                {{ countries[profile.billingAddress.country].name }}
                            </li>
                        </ul>
                        <div class="card-body">
                            <button :disabled="edit.shipping"
                                    class="btn btn-primary float-end"
                                    @click="edit.billing = true;">
                                {{ $t('actions.edit') }}
                            </button>
                        </div>
                    </template>
                    <div v-if="edit.billing && !loading.billing" class="card-body">
                        <div class="input-group mb-3">
                            <span id="billing-address-salutation"
                                  class="input-group-text w-90px">{{ $tc('salutation', 1) }}</span>
                            <select v-model="profile.billingAddress.salutation"
                                    :aria-label="$tc('salutation', 1)"
                                    class="form-select">
                                <option disabled value="">{{ $t('actions.please-select') }}</option>
                                <option value="MR">{{ $t('salutations.MR') }}</option>
                                <option value="MS">{{ $t('salutations.MS') }}</option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-first-name"
                                  class="input-group-text w-90px">{{ $tc('first-name', 1) }}</span>
                            <input v-model="profile.billingAddress.firstName"
                                   :aria-label="$tc('first-name', 1)"
                                   aria-describedby="billing-address-first-name"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-last-name" class="input-group-text w-90px">{{
                                    $tc('last-name', 1)
                                }}</span>
                            <input v-model="profile.billingAddress.lastName"
                                   :aria-label="$tc('last-name', 1)"
                                   aria-describedby="billing-address-last-name"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-company" class="input-group-text w-90px">{{
                                    $tc('company', 1)
                                }}</span>
                            <input v-model="profile.billingAddress.company"
                                   :aria-label="$tc('company', 1)"
                                   aria-describedby="billing-address-company"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-street" class="input-group-text w-90px">{{
                                    $tc('street', 1)
                                }}</span>
                            <input v-model="profile.billingAddress.street"
                                   :aria-label="$tc('street', 1)"
                                   aria-describedby="billing-address-street"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-postcode" class="input-group-text w-90px">{{
                                    $tc('postcode', 1)
                                }}</span>
                            <input v-model="profile.billingAddress.postcode"
                                   :aria-label="$tc('postcode', 1)"
                                   aria-describedby="billing-address-postcode"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-city" class="input-group-text w-90px">{{ $tc('city', 1) }}</span>
                            <input v-model="profile.billingAddress.city"
                                   :aria-label="$tc('city', 1)"
                                   aria-describedby="billing-address-city"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-state" class="input-group-text w-90px">{{
                                    $tc('state', 1)
                                }}</span>
                            <input v-model="profile.billingAddress.state"
                                   :aria-label="$tc('state', 1)"
                                   aria-describedby="billing-address-state"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="billing-address-country" class="input-group-text w-90px">{{
                                    $tc('country', 1)
                                }}</span>
                            <select v-model="profile.billingAddress.country"
                                    :aria-label="$tc('country', 1)"
                                    class="form-select">
                                <option disabled value="">{{ $t('actions.please-select') }}</option>
                                <option value="AT">{{ countries['AT'].name }}</option>
                                <option value="DE">{{ countries['DE'].name }}</option>
                            </select>
                        </div>
                        <button class="btn btn-primary float-end"
                                @click="updateUserProfile">
                            {{ $t('actions.save') }}
                        </button>
                        <button class="btn btn-light float-end me-2"
                                @click="edit.billing = false;">
                            {{ $t('actions.cancel') }}
                        </button>
                    </div>
                    <div v-if="loading.billing"
                         class="modal-body d-flex justify-content-center align-items-center">
                        <component-spinner class="py-4" color="text-primary" type="spinner-grow"/>
                    </div>
                </div>
            </div>

            <!-- SHIPPING ADDRESS -->
            <div class="col-6">
                <div class="card">
                    <div class="card-header">
                        <strong>{{ $t('shipping-address') }}</strong>
                    </div>
                    <template v-if="!loading.shipping && !edit.shipping">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('salutation', 1) }}
                                </strong>
                                {{ $t('salutations.' + profile.shippingAddress.salutation) }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('first-name', 1) }}
                                </strong>
                                {{ profile.shippingAddress.firstName }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('last-name', 1) }}
                                </strong>
                                {{ profile.shippingAddress.lastName }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('company', 1) }}
                                </strong>
                                {{ profile.shippingAddress.company }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('street', 1) }}
                                </strong>
                                {{ profile.shippingAddress.street }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('postcode', 1) }}
                                </strong>
                                {{ profile.shippingAddress.postcode }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('city', 1) }}
                                </strong>
                                {{ profile.shippingAddress.city }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('state', 1) }}
                                </strong>
                                {{ profile.shippingAddress.state }}
                            </li>
                            <li class="list-group-item">
                                <strong class="w-120px">
                                    {{ $tc('country', 1) }}
                                </strong>
                                {{ countries[profile.shippingAddress.country].name }}
                            </li>
                        </ul>
                        <div class="card-body text-end">
                            <button :disabled="edit.billing"
                                    class="btn btn-primary"
                                    @click="edit.shipping = true;">
                                {{ $t('actions.edit') }}
                            </button>
                        </div>
                    </template>
                    <div v-if="edit.shipping && !loading.shipping" class="card-body">
                        <div class="input-group mb-3">
                            <span id="shipping-address-salutation"
                                  class="input-group-text w-90px">{{ $tc('salutation', 1) }}</span>
                            <select v-model="profile.shippingAddress.salutation"
                                    :aria-label="$tc('salutation', 1)"
                                    class="form-select">
                                <option disabled value="">{{ $t('actions.please-select') }}</option>
                                <option value="MR">{{ $t('salutations.MR') }}</option>
                                <option value="MS">{{ $t('salutations.MS') }}</option>
                            </select>
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-first-name"
                                  class="input-group-text w-90px">{{ $tc('first-name', 1) }}</span>
                            <input v-model="profile.shippingAddress.firstName"
                                   :aria-label="$tc('first-name', 1)"
                                   aria-describedby="shipping-address-first-name"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-last-name" class="input-group-text w-90px">{{
                                    $tc('last-name', 1)
                                }}</span>
                            <input v-model="profile.shippingAddress.lastName"
                                   :aria-label="$tc('last-name', 1)"
                                   aria-describedby="shipping-address-last-name"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-company" class="input-group-text w-90px">{{
                                    $tc('company', 1)
                                }}</span>
                            <input v-model="profile.shippingAddress.company"
                                   :aria-label="$tc('company', 1)"
                                   aria-describedby="shipping-address-company"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-street" class="input-group-text w-90px">{{
                                    $tc('street', 1)
                                }}</span>
                            <input v-model="profile.shippingAddress.street"
                                   :aria-label="$tc('street', 1)"
                                   aria-describedby="shipping-address-street"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-postcode" class="input-group-text w-90px">{{
                                    $tc('postcode', 1)
                                }}</span>
                            <input v-model="profile.shippingAddress.postcode"
                                   :aria-label="$tc('postcode', 1)"
                                   aria-describedby="shipping-address-postcode"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-city" class="input-group-text w-90px">{{ $tc('city', 1) }}</span>
                            <input v-model="profile.shippingAddress.city"
                                   :aria-label="$tc('city', 1)"
                                   aria-describedby="shipping-address-city"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-state" class="input-group-text w-90px">{{
                                    $tc('state', 1)
                                }}</span>
                            <input v-model="profile.shippingAddress.state"
                                   :aria-label="$tc('state', 1)"
                                   aria-describedby="shipping-address-state"
                                   class="form-control"
                                   type="text">
                        </div>
                        <div class="input-group mb-3">
                            <span id="shipping-address-country" class="input-group-text w-90px">{{
                                    $tc('country', 1)
                                }}</span>
                            <select v-model="profile.shippingAddress.country"
                                    :aria-label="$tc('country', 1)"
                                    class="form-select">
                                <option disabled value="">{{ $t('actions.please-select') }}</option>
                                <option value="AT">{{ countries['AT'].name }}</option>
                                <option value="DE">{{ countries['DE'].name }}</option>
                            </select>
                        </div>
                        <button class="btn btn-primary float-end"
                                @click="updateUserProfile">
                            {{ $t('actions.save') }}
                        </button>
                        <button class="btn btn-light float-end me-2"
                                @click="edit.shipping = false;">
                            {{ $t('actions.cancel') }}
                        </button>
                    </div>
                    <div v-if="loading.shipping"
                         class="modal-body d-flex justify-content-center align-items-center">
                        <component-spinner class="py-4" color="text-primary" type="spinner-grow"/>
                    </div>
                </div>
            </div>

        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import {countries} from "countries-list";

export default {
    name: "PageOrganization",
    components: {LayoutFullWidth, ComponentSpinner},
    data() {
        return {
            countries,
            loading: {
                billing: true,
                shipping: true
            },
            edit: {
                billing: false,
                shipping: false
            }
        }
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        }
    },
    methods: {
        async loadUserProfile() {
            await this.$store.dispatch('USER/GET_PROFILE');
            this.loading.billing = false;
            this.loading.shipping = false;
        },
        async updateUserProfile() {
            if (this.edit.billing) {
                this.loading.billing = true;
            }

            if (this.edit.shipping) {
                this.loading.shipping = true;
            }

            this.$store.dispatch('USER/UPDATE_PROFILE', this.profile)
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.profile-updated')
                    });
                    this.loading.billing = false;
                    this.loading.shipping = false;
                    this.edit.billing = false;
                    this.edit.shipping = false;
                    this.$gtag.event('USER_UPDATE_PROFILE');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.profile-updated')
                    });
                    this.loading.billing = false;
                    this.loading.shipping = false;
                    this.edit.billing = false;
                    this.edit.shipping = false;
                });
        }
    },
    async beforeMount() {
        await this.loadUserProfile();
    }
}
</script>

<style lang="scss" scoped>
.w-120px {
    display: inline-block;
    min-width: 120px;
}

.w-90px {
    width: 90px;
}
</style>